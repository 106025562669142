import { AppEventsStore } from "./AppEventsStore";

export class AppEvent<EventDataType> {
  static appEventNames = new Set<string>();

  constructor(private readonly eventName: string) {
    if (AppEvent.appEventNames.has(eventName)) {
      throw new Error(`The ${eventName} event already exists`);
    }
    AppEvent.appEventNames.add(eventName);
  }

  subscribe = (listener: (data: EventDataType) => void) => {
    AppEventsStore.get().appEvents.on(this.eventName, listener);
  };

  unsubscribe = (listener: (data: EventDataType) => void) => {
    AppEventsStore.get().appEvents.off(this.eventName, listener);
    AppEventsStore.get().appEvents.removeListener(this.eventName, listener);
  };

  publish = (data: EventDataType) => {
    AppEventsStore.get().appEvents.emit(this.eventName, data);
  };

  // Useful for testing
  unsubscribeAll = () => {
    AppEventsStore.get().appEvents.removeAllListeners();
  };
}
