"use client";

import { getSettingFactory } from "@faire/web/common/SerializedSetting";

import { getSettingsValues } from "@faire/retailer/serialized-data/getSettingsValues";

/**
 * Functions that provides synchronous access to settings that are serialized to the page, while handling frontend
 * overrides and default values if the setting is missing.
 *
 * RSC: these functions only work in client components. Consider migrating uses to client
 * components (which can be server rendered!) or using fetchServerSideSetting
 */
const {
  getSettingValueAndAssign,
  getSettingValueWithoutAssignment,
  assignSetting,
  // eslint-disable-next-line @faire/ban-top-level-request-specific-data -- false positive
} = getSettingFactory<"RETAILER" | "LOGGED_IN_RETAILER">(() =>
  getSettingsValues()
);

export {
  /**
   * @deprecated Use useSetting and useAssignSetting instead.
   */
  getSettingValueAndAssign,
  /**
   * @deprecated Use useSetting instead.
   */
  getSettingValueWithoutAssignment,
  /**
   * @deprecated Use useAssignSetting instead.
   */
  assignSetting,
};
