import { ICartItemV2 } from "@faire/web-api/faire/carts/ICartItemV2";
import { IBrand } from "@faire/web-api/indigofair/data/IBrand";
import { IBrandLead } from "@faire/web-api/indigofair/data/IBrandLead";
import { IFaireMoney } from "@faire/web-api/indigofair/data/IFaireMoney";
import { IFlashSale } from "@faire/web-api/indigofair/data/IFlashSale";
import { IItemPromoPrice } from "@faire/web-api/indigofair/data/IItemPromoPrice";
import { IMembership } from "@faire/web-api/indigofair/data/IMembership";
import { IOrder } from "@faire/web-api/indigofair/data/IOrder";
import { IProduct } from "@faire/web-api/indigofair/data/IProduct";
import { IRetailer } from "@faire/web-api/indigofair/data/IRetailer";
import { IUser } from "@faire/web-api/indigofair/data/IUser";

import { IProductItem } from "@faire/retailer/lib/cartTools";
import { AppEvent } from "@faire/retailer/packages/core/services/AppEvents";

import { AddToCartVariants } from "./stores/domain/CartStore/triggerAddToCartEvent";

export interface IUserAndRetailer {
  user: IUser;
  retailer: IRetailer;
}

export namespace IUserAndRetailer {
  export const build = (
    partial?: Partial<IUserAndRetailer> | undefined
  ): IUserAndRetailer => ({
    user: IUser.build(partial?.user),
    retailer: IRetailer.build(partial?.retailer),
  });
}

export interface IRetailerPurchaseEvent {
  givenProductItems: IProductItem[];
  retailer?: IRetailer;
  order: IOrder;
  brands: Partial<Record<string, IBrand>>;
}

export interface IAddedCartItem {
  token: string;
  brandToken: string;
  productToken: string;
  productOptionToken: string;
  productOptionType: keyof typeof ICartItemV2.Type | undefined;
  isOpenSizing: boolean;
  quantity: number;
  addedQuantity: number;
  addedPrice: number;
  addedTester: boolean;
  styleGroupName: string | undefined;
  styleName: string | undefined;
  totalPriceCents: number | undefined;
  totalPrice: IFaireMoney | undefined;
  productName: string | undefined;
  imageUrl: string | undefined;
  totalPromoPrice: IItemPromoPrice | undefined;
}

export namespace IAddedCartItem {
  export const build = (partial: Partial<IAddedCartItem>): IAddedCartItem => ({
    token: "",
    brandToken: "",
    productToken: "",
    productOptionToken: "",
    productOptionType: undefined,
    isOpenSizing: false,
    quantity: 0,
    addedQuantity: 0,
    addedPrice: 0,
    addedTester: false,
    styleGroupName: undefined,
    styleName: undefined,
    totalPriceCents: undefined,
    totalPrice: undefined,
    productName: undefined,
    imageUrl: undefined,
    totalPromoPrice: undefined,
    ...(partial ?? {}),
  });
}

export interface IAddToCartBrandData {
  name: string | undefined;
  token: string | undefined;
  hasEarnedPromo: boolean | undefined;
  brandPromoDescription: string | undefined;
  minimumOrderAmountCents: number;
  hasCartItemIssues: boolean;
  brandTotalAmount: number;
  isAboveFirstOrderMinimumThreshold: boolean;
  deltaFromMinimum: IFaireMoney | undefined;
}

export namespace IAddToCartBrandData {
  export const build = (
    partial?: Partial<IAddToCartBrandData> | undefined
  ): IAddToCartBrandData => ({
    name: partial?.name,
    token: partial?.token,
    hasEarnedPromo: partial?.hasEarnedPromo,
    brandPromoDescription: partial?.brandPromoDescription,
    minimumOrderAmountCents: partial?.minimumOrderAmountCents ?? 0,
    hasCartItemIssues: partial?.hasCartItemIssues ?? false,
    brandTotalAmount: partial?.brandTotalAmount ?? 0,
    isAboveFirstOrderMinimumThreshold:
      partial?.isAboveFirstOrderMinimumThreshold ?? false,
    deltaFromMinimum: partial?.deltaFromMinimum,
  });
}

interface IInitiateCheckoutBrandCart {
  cartValue: number;
}

interface IInitiateCheckoutAllCarts {
  cartValue: number;
}

interface IInitiateCheckoutFlashSale {
  cartValue: number;
}

export interface ICartUpdateEventV2 {
  skipItemInventoryFetch?: boolean;
  skipCartInvalidation?: boolean;
}

export interface IAddToCartEvent {
  /**
   * Note that the quantities for these items reflect the
   * new (updated) quantities after adding them.
   * This is relevant when adding more of an item already in the cart.
   */
  added: IAddedCartItem[];
  brandData: IAddToCartBrandData;
  minimumReached: boolean;
  remaining: IFaireMoney;
  maximumExceeded: boolean;
  exceeding: IFaireMoney;
  amountAdded: IFaireMoney;
  isAlreadyOverMinimum: boolean;
  startTime: number;
  variant: AddToCartVariants;
  cartItemsTooltips?: Map<string, string>;
}

export namespace IAddToCartEvent {
  export const build = (
    partial?: Partial<IAddToCartEvent> | undefined
  ): IAddToCartEvent => ({
    added: [],
    brandData: IAddToCartBrandData.build(),
    minimumReached: false,
    remaining: IFaireMoney.build(),
    maximumExceeded: false,
    exceeding: IFaireMoney.build(),
    amountAdded: IFaireMoney.build(),
    isAlreadyOverMinimum: false,
    startTime: 0,
    variant: AddToCartVariants.QUICK_ADD_BTN,
    ...(partial ?? {}),
  });
}

export interface IAddCollectionToCartEvent {
  collectionToken: string;
  isQuickAdd: boolean;
}

export interface IBrandBlogContentSubmission {
  stockists?: number;
}

export interface IPersonalInfoSubmission {
  country: string;
  is_sub_user: boolean;
}

export interface IStoreDetailsSubmission {
  store_type: string;
  years_in_business: string;
  annual_sales: string;
}

export interface IDescribeStoreSubmission {
  store_description: string;
}

export const SignUp = new AppEvent<IUserAndRetailer>("RETAILER_SIGN_UP_EVENT");

export const SignUpPersonalInfoNextPressed =
  new AppEvent<IPersonalInfoSubmission>("PERSONAL_INFO_NEXT_PRESSED");

export const SignUpStoreDetailNextPressed =
  new AppEvent<IStoreDetailsSubmission>("STORE_DETAIL_NEXT_PRESSED");

export const SignUpDescribeStoreNextPressed =
  new AppEvent<IDescribeStoreSubmission>("DESCRIBE_STORE_NEXT_PRESSED");

export const OrderSubmitted = new AppEvent<IRetailerPurchaseEvent>(
  "RETAILER_PURCHASE_EVENT"
);

export const OrderCanceled = new AppEvent<void>("RETAILER_CANCEL_ORDER_EVENT");

export const AddAddress = new AppEvent<void>("RETAILER_ADD_ADDRESS");
export const ChangeShippingAddress = new AppEvent<void>(
  "RETAILER_CHANGE_SHIPPING_ADDRESS"
);

export const CartUpdatedV2 = new AppEvent<ICartUpdateEventV2>(
  "CART_UPDATED_V2"
);

export const AddToCart = new AppEvent<IAddToCartEvent>("CART_ITEMS_UPDATED");

export const AddToCartNotificationFinished = new AppEvent<void>(
  "CART_ITEMS_UPDATED_NOTIFICATION_FINISHED"
);

/**
 * An event fired whenever a notification is to be shown, used to close any currently shown
 * notification (we show only one at a time).
 */
export const NotificationStarting = new AppEvent<string>(
  "NOTIFICATION_STARTING"
);

export const AddCollectionToCart = new AppEvent<IAddCollectionToCartEvent>(
  "RETAILER_ADD_COLLECTION_TO_CART"
);

export const InitiateCheckoutBrandCart =
  new AppEvent<IInitiateCheckoutBrandCart>("INITIATE_CHECKOUT_BRAND_CART");

export const InitiateCheckoutAllCarts = new AppEvent<IInitiateCheckoutAllCarts>(
  "INITIATE_CHECKOUT_ALL_CARTS"
);

export const InitiateCheckoutFlashSale =
  new AppEvent<IInitiateCheckoutFlashSale>("INITIATE_CHECKOUT_FLASH_SALE");

export const FlashSaleOrderSubmitted = new AppEvent<IFlashSale>(
  "FLASH_SALE_ORDER_SUBMITTED"
);

export const ViewProduct = new AppEvent<IProduct>("VIEW_PRODUCT");

export const ViewBrand = new AppEvent<Pick<IBrand, "token" | "is_internal">>(
  "VIEW_BRAND"
);

export const InsiderMembershipPurchase = new AppEvent<IMembership>(
  "INSIDER_MEMBERSHIP_PURCHASE"
);

export const SearchForProduct = new AppEvent<string>("SEARCH_FOR_PRODUCT");

export const PaidInvoicesSuccess = new AppEvent<void>("PAID_INVOICES_SUCCESS");

export const LimitIncreaseApplicationSubmitted = new AppEvent<void>(
  "LIMIT_INCREASE_APPLICATION_SUBMITTED"
);

export const MakerSubmitApplication = new AppEvent<IBrandLead>(
  "MAKER_APPLY_EVENT"
);
export const MakerAdPageApply = new AppEvent<IBrandLead>("AD_PAGE_APPLY");

export const PageInitialized = new AppEvent<void>("PAGE_INIT");

export const RefusedRedirectToExternalUrl = new AppEvent<string>(
  "REFUSED_REDIRECT_TO_EXTERNAL_URL"
);

export const BrandBlogContentFormSubmission =
  new AppEvent<IBrandBlogContentSubmission>(
    "BRAND_BLOG_CONTENT_FORM_SUBMISSION"
  );

export const InsiderMembershipMutated = new AppEvent<void>(
  "INSIDER_MEMBERSHIP_MUTATED"
);

export const OpenWithFairePageView = new AppEvent<void>(
  "OPEN_WITH_FAIRE_PAGE_VIEW"
);

export const OpenWithFairePageYesFundingSelected = new AppEvent<void>(
  "OPEN_WITH_FAIRE_PAGE_YES_FUNDING_SELECTED"
);

export const OpenWithFaireSignUpApplicationView = new AppEvent<void>(
  "OPEN_WITH_FAIRE_SIGN_UP_APPLICATION_VIEW"
);

export const OpenWithFaireStoreInfoSectionNextPressed = new AppEvent<void>(
  "OPEN_WITH_FAIRE_STORE_INFO_SECTION_NEXT_PRESSED"
);

export const OpenWithFaireOwnershipSectionNextPressed = new AppEvent<void>(
  "OPEN_WITH_FAIRE_OWNERSHIP_SECTION_NEXT_PRESSED"
);

export const OpenWithFaireFinancialSectionNextPressed = new AppEvent<void>(
  "OPEN_WITH_FAIRE_FINANCIAL_SECTION_NEXT_PRESSED"
);

export const OpenWithFaireAdditionalSectionNextPressed = new AppEvent<void>(
  "OPEN_WITH_FAIRE_ADDITIONAL_SECTION_NEXT_PRESSED"
);
