import {
  RetailerStore,
  useRetailerStore,
} from "@faire/retailer/stores/domain/RetailerStore";
import {
  UserStore,
  useUserStore,
} from "@faire/retailer/stores/domain/UserStore";

import { getIsBrandPreview, useIsBrandPreview } from "./getIsBrandPreview";

/**
 * @deprecated Use `useIsLoggedInRetailer` instead
 */
export const isLoggedInRetailer = (): boolean =>
  !!UserStore.get().userToken &&
  !!RetailerStore.get().retailerToken &&
  !RetailerStore.get().isGuestRetailer &&
  !getIsBrandPreview();

export const useIsLoggedInRetailer = (): boolean => {
  const { userToken } = useUserStore(["userToken"]);
  const { retailerToken, isGuestRetailer } = useRetailerStore([
    "retailerToken",
    "isGuestRetailer",
  ]);
  const isBrandPreview = useIsBrandPreview();
  return !!userToken && !!retailerToken && !isBrandPreview && !isGuestRetailer;
};

export const useIsLoggedInRetailerOrBrandPreview = (): boolean => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const isBrandPreview = useIsBrandPreview();
  return isLoggedInRetailer || isBrandPreview;
};

/**
 * @deprecated Use `useIsLoggedInRetailerOrBrandPreview` instead
 */
export const isLoggedInRetailerOrBrandPreview = (): boolean =>
  isLoggedInRetailer() || getIsBrandPreview();
